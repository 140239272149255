import * as React from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CRestaurantJumbotron,
  CBreadCrumb,
  LWrap,
  Search,
  LRestaurantPickup,
  CHeroMedia,
  CSectHeading,
  CCard,
  CHeroImg,
  CSpSliderCard,
  CSectTitle,
  CBtnList,
  LMenu,
  LRestaurant,
  CFadeSlider,
  ShopContents,
  LRestaurantContact,
} from '../../../components/_index';
import shopGet from '../../../utils/shop-get';
import { shopFixedChoice } from '../../../utils/shop-choice';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CRestaurantJumbotron
        size="large"
        data={{
          location: {
            floor: '68F',
            category: '中国料理',
          },
          logo: {
            src: '/assets/images/restaurants/kohen/logo_top.svg',
            alt: '',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/restaurants/kohen/kv.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/kohen/kv__sp.png',
              },
            },
            {
              img: {
                src: '/assets/images/restaurants/kohen/kv02.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/kohen/kv02__sp.png',
              },
            },
            {
              img: {
                src: '/assets/images/restaurants/kohen/kv03.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/kohen/kv03__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: 'レストラン',
                path: '/restaurants/',
              },
            ],
            current: {
              label: '68F 中国料理 皇苑',
            },
          }}
        />
      </CRestaurantJumbotron>
      <Search formId="toppage" currentIndex={1} selected="yrph-koh-en" />
      <section className="l_sect">
        <LWrap>
          <CHeroMedia
            data={[
              {
                img: {
                  src: '/assets/images/restaurants/kohen/img_media.png',
                  alt: '',
                },
                title: (
                  <>
                    広大な景色と溶け合う
                    <br />
                    中国料理レストラン
                  </>
                ),
                text: (
                  <>
                    地上268mから一望する横浜の街並み。
                    <br />
                    空気が澄んだ晴れた日には、彼方に雄大な富士山が。
                  </>
                ),
                btn: {
                  label: '眺望',
                  link: {
                    href: '/restaurants/kohen/view/',
                  },
                },
              },
              {
                img: {
                  src: '/assets/images/restaurants/kohen/img_media02.png',
                  alt: '',
                },
                title: (
                  <>
                    中国語で「ロイヤルパーク」
                    <br />
                    の意味を持つ「皇苑」
                  </>
                ),
                text: (
                  <>
                    白と黒を基調としたモダンなホール席から、黒を基調としたシックな個室など様々なシーンに合わせた空間をご用意。優雅なひとときをお過ごしいただけます。
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="u_mbLarge">
        <LWrap>
          <CSectHeading
            data={{
              title: (
                <>
                  ORIGINAL <br className="u_sp" />
                  MENU
                </>
              ),
              text: (
                <>
                  広東料理を中心に、
                  <br />
                  中国各地の名菜が並ぶ
                  <br />
                  多彩なメニュー
                </>
              ),
            }}
          />
          <CHeroImg
            exClass="u_mb40"
            img={{
              src: '/assets/images/restaurants/kohen/img_originalmenu.png',
              alt: '',
            }}
          />
          <CSpSliderCard
            exClass="u_mb40"
            col={3}
            data={[
              {
                img: {
                  src: '/assets/images/restaurants/kohen/img_originalmenu02.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/restaurants/kohen/img_originalmenu03.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/restaurants/kohen/img_originalmenu04.png',
                  alt: '',
                },
              },
            ]}
          />
          {/* <div className="u_centerring">
            <CFadeSlider
              exClass="u_mb40"
              data={[
                {
                  img: {
                    src: '/assets/images/restaurants/kohen/img_originalmenu.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/kohen/img_originalmenu02.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/kohen/img_originalmenu03.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/kohen/img_originalmenu04.png',
                    alt: '',
                  },
                },
              ]}
            />
          </div> */}
          <CHeroMedia
            data={[
              {
                img: {
                  src: '/assets/images/restaurants/kohen/img_media03.png',
                  alt: '',
                },
                title: <></>,
                text: (
                  <>
                    広東料理の焼き物、飲茶、魚介を中心に、中国各地の名菜が楽しめる多彩なメニューが自慢です。
                    <br />
                    お客様に味付けを選んでいただく料理は、味覚だけでなく香り、色彩も楽しんでいただけるよう工夫を凝らしています。
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <section>
        <LWrap>
          <CSectTitle
            title={{
              en: 'RESTAURANT',
              ja: '店舗情報',
            }}
          />
          <ShopContents
            data={shopFixedChoice(shopGet(), 'kohen_top').contents1}
          />
        </LWrap>
      </section>
      <LMenu
        data={[
          {
            img: {
              src: '/assets/images/restaurants/kohen/img_menu.png',
            },
            imgSp: {
              src: '/assets/images/restaurants/kohen/img_menu__sp.png',
            },
            title: <>LUNCH</>,
            btn: {
              label: <>ランチ</>,
              link: {
                href: '/restaurants/kohen/menu/#lunch',
              },
            },
          },
          {
            img: {
              src: '/assets/images/restaurants/kohen/img_menu03.png',
            },
            imgSp: {
              src: '/assets/images/restaurants/kohen/img_menu__sp03.png',
            },
            title: <>DINNER</>,
            btn: {
              label: <>ディナー</>,
              link: {
                href: '/restaurants/kohen/menu/#dinner',
              },
            },
          },
          {
            img: {
              src: '/assets/images/restaurants/kohen/img_menu04.png',
            },
            imgSp: {
              src: '/assets/images/restaurants/kohen/img_menu__sp04.png',
            },
            title: <>A LA CARTE</>,
            btn: {
              label: <>アラカルト</>,
              link: {
                href: '/restaurants/kohen/menu/#alacarte',
              },
            },
          },
          {
            img: {
              src: '/assets/images/restaurants/kohen/img_menu05.png',
            },
            imgSp: {
              src: '/assets/images/restaurants/kohen/img_menu__sp05.png',
            },
            title: <>DRINK</>,
            btn: {
              label: <>ドリンク</>,
              link: {
                href: '/restaurants/kohen/menu/#drink',
              },
            },
          },
        ]}
      />
      <CBtnList
        data={[
          {
            label: 'すべてのメニューを見る',
            link: {
              href: '/restaurants/kohen/menu/',
            },
          },
        ]}
      />
      <LRestaurantPickup restaurant={['皇苑']} />
      <section className="l_sect">
        <LWrap>
          <CSectTitle title={{ ja: 'プラン', en: 'PLAN' }} />
          <CCard
            data={[
              {
                link: {
                  href: '/anniversary/restaurants_plan/',
                },
                img: {
                  src: '/assets/images/restaurants/img_plan.png',
                  alt: '',
                },
                title: <>お祝い・記念日</>,
                content: (
                  <>
                    レストランでの特別なひとときに、より華を添えるプランをご用意しました。心を込めたお料理とおもてなしで素敵な時間を演出いたします。
                  </>
                ),
              },
              {
                link: {
                  href: '/restaurants/private/',
                },
                img: {
                  src: '/assets/images/restaurants/img_plan02.png',
                  alt: '',
                },
                title: <>個室のご案内</>,
                content: (
                  <>ゆとりのある個室は、大切な会合やご接待にも最適です。</>
                ),
              },
              {
                link: {
                  href: '/anniversary/restaurants_plan/celebration/',
                },
                img: {
                  src: '/assets/images/restaurants/img_plan03.png',
                  alt: '',
                },
                title: <>パーティープラン</>,
                content: (
                  <>
                    納涼会や歓迎会など、パーティーに最適なプランをご紹介いたします。
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <section>
        <LRestaurant />
      </section>
      <section className="l_sect">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
            <li>料金には消費税、サービス料が含まれます。</li>
            <li>料理の表示価格は、特別な記載がない限り1名様分の料金です。</li>
            <li>
              テイクアウト商品の料金には消費税（8％）のみ含まれます。コフレ店内をご利用の場合は、サービス料を加算、消費税は10％となります。
            </li>
            <li>入荷状況によりメニュー内容に変更がある場合がございます。</li>
            <li>
              「神奈川県公共的施設における受動喫煙防止条例」に基づきレストラン・バーを全席禁煙とさせていただいております。
            </li>
            <li>
              ご入店の際は、タンクトップ・短パン・ビーチサンダルなどはご遠慮いただいております。
            </li>
          </ul>
        </LWrap>
      </section>
      <LRestaurantContact selected="yrph-koh-en" />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
